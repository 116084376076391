import React from "react";
import { Redirect } from "react-router-dom";

import { PrismaSDK, UsabilityResponse } from "@prismadelabs/prismaid";
import anime from "animejs";

// components
import { Helmet } from "react-helmet";
import ScaledImage from "./ScaledImage";
import { Circle } from "./svg/Circle";

// functions
import { Z } from "./Z";
import {
  alignHorizontally,
  alignVertically,
  getWidth,
  getHeight,
} from "../js/align";

import { getScaleFactor } from "../js/scale";

// images
import z_lines from "../assets/img/swipe/scan_z-lines.png";
import placement from "../assets/img/swipe/scan_placement.png";
// FIXME: which one?
import holdHere from "../assets/img/swipe/scan_hier-halten.png";
// import holdHere from "../assets/img/swipe/scan_karte-halten.png";
import card from "../assets/img/swipe/scan_card.png";
import thumbTouch from "../assets/img/swipe/scan_touch.png";
import thumb from "../assets/img/swipe/scan_thumb.png";
import handTouch from "../assets/img/swipe/scan_touch.png";
import hand from "../assets/img/swipe/scan_hand.png";

import sound from "../assets/sounds/soft-click-alert-4.mp3";

//
type ZScreenProps = {
  setTemp: any;
  setDeviceSupport: any;
  setBrowserSupport: any;
  orientation: string;
};

type ZScreenStates = {
  scaleFactor: number;
  redirect: any;
  tempCount: number;
};

class ZScreen extends React.Component<ZScreenProps, ZScreenStates> {
  private sdk: PrismaSDK;
  private z: Z;

  constructor(props: ZScreenProps) {
    super(props);

    this.state = {
      // divided by 2, because @2 images are used
      scaleFactor: 0.5,
      redirect: null,
      tempCount: 1,
    };

    // FIXME: remove API url for production use!
    const apiKey = "X8Ku8KnLU04Cwyiy83f7tO4ADzLS7Jbjz3iX0Rj0";
    this.sdk = new PrismaSDK(apiKey);
    this.z = new Z(this.sdk);
    this.z.z1event = this.z1Detected;
    this.z.z2event = this.z2Detected;
    this.z.z3event = this.z3Detected;
    this.z.z4event = this.z4Detected;
    this.z.zSuccessEvent = this.zSuccess;
    this.z.zNoSuccessEvent = this.zNoSuccess;

    this.sdk.getInitialisationSubject().subscribe((response) => {
      var scale = getScaleFactor(response.ppi, response.devicePixelRatio);

      if (!Number.isNaN(scale)) {
        this.setState({ scaleFactor: scale });
      }
    });

    this.sdk.getUsabilitySubject().subscribe((response: UsabilityResponse) => {
      // device not supported
      if (response.event === "device_not_supported") {
        console.log("Device is not supported");
        this.props.setDeviceSupport(false);
      }

      // browser_not_supported (->android firefox)
      if (response.event === "browser_not_supported") {
        console.log("Browser is not supported");
        this.props.setBrowserSupport(false);
      }
    });

    // not mobile
    if (!/Mobi|Android/i.test(navigator.userAgent)) {
      console.log("Device is not mobile");
      this.props.setDeviceSupport(false);
    }
  }

  componentDidMount() {
    this.initialisePrismaSDK();
  }

  componentDidUpdate(prevProps: any) {
    if (this.props.orientation !== prevProps.orientation) {
      console.log("componentDidUpdate: orientation", this.props.orientation);
      window.location.reload();
    }
  }

  initialisePrismaSDK = () => {
    this.sdk.getDetectionSuccessSubject().subscribe((response) => {
      console.log("*) detection success:", response.description());
    });

    this.sdk.getDetectionErrorSubject().subscribe((response) => {
      console.log("*) detection error:", response.description());

      response.hints.forEach((hint) => {
        console.log("*) hint:", hint.description());
      });
    });

    this.sdk.getInitialisationSubject().subscribe((response) => {
      // INFO: prepareUI cannot be called before component is mounted, as elements might not have a width/height
      this.prepareUI();
    });
  };

  calibrateCardBounds = () => {
    const card = document.getElementById("card");
    if (!card) return;

    const cardRect = card.getBoundingClientRect();
    console.log("card boundingClientRect", cardRect);
    const screen = document.querySelector("#swipeScreen");
    if (screen) {
      this.z.attachToElement(screen);
    }
  };

  prepareUI = () => {
    // align images
    alignHorizontally("z_lines", "center");
    alignVertically("z_lines", "center");

    alignHorizontally("correctCircle", "center");
    alignVertically("correctCircle", "center");

    alignHorizontally("pulse1", "center");
    alignVertically("pulse1", "center");

    alignHorizontally("pulse2", "center");
    alignVertically("pulse2", "center");

    alignHorizontally("pulse3", "center");
    alignVertically("pulse3", "center");

    alignHorizontally("placement", "center");
    alignVertically("placement", "center");

    alignHorizontally("card", "center");
    alignVertically("card", "center");
    this.calibrateCardBounds();

    alignHorizontally("holdHere", "center", this.getScaled(-290));
    alignVertically("holdHere", "center");

    alignHorizontally("thumbTouch", "center", this.getScaled(-225));
    alignVertically("thumbTouch", "center");

    alignHorizontally("thumb", "center", this.getScaled(-420));
    alignVertically("thumb", "center", this.getScaled(110));

    alignHorizontally("handTouch", "center", this.getScaled(-145));
    alignVertically("handTouch", "center", this.getScaled(-230));

    alignHorizontally("hand", "center", this.getScaled(400));
    alignVertically("hand", "center", this.getScaled(110));

    this.showFixedImages();

    setTimeout(() => {
      this.startDemoAnimation();
    }, 1000);
  };

  showFixedImages = () => {
    anime({
      targets: ["#z_lines", "#pulse1", "#pulse2", "#pulse3", "#placement"],
      opacity: 1.0,
      duration: 200,
      easing: "linear",
    });
  };

  startDemoAnimation = () => {
    // timeline
    // see https://animejs.com/documentation/#timelineOffsets for time offsets
    var demoAnimation = anime.timeline({
      easing: "linear",
    });

    // card
    demoAnimation.add({
      targets: ["#card"],
      keyframes: [
        // prepare
        {
          translateX: 2 * getWidth()! * this.state.scaleFactor,
          translateY: 0 - (getHeight()! / 2) * this.state.scaleFactor,
          duration: 100,
        },
        {
          opacity: 1.0,
          duration: 100,
        },
        // move card in
        {
          translateX: 0,
          translateY: 0,
          duration: 1000,
        },
      ],
      complete: function () {
        anime({
          targets: "#placement",
          opacity: 0.0,
          duration: 100,
        });

        anime({
          targets: "#pulse1",
          scale: 0.8,
          opacity: 0.0,
          duration: 300,
        });
        anime({
          targets: "#pulse2",
          scale: 0.7,
          opacity: 0.0,
          duration: 300,
        });
        anime({
          targets: "#pulse3",
          scale: 0.6,
          opacity: 0.0,
          duration: 300,
        });
      },
    });

    demoAnimation.add({
      targets: ["#holdHere"],
      opacity: 1,
      duration: 1000,
    });

    // thumb
    demoAnimation.add({
      targets: ["#thumb"],
      keyframes: [
        // prepare
        {
          translateX: -getWidth()! * this.state.scaleFactor,
          translateY: (getHeight()! / 2) * this.state.scaleFactor,
          scale: 1.3,
          duration: 100,
        },
        {
          opacity: 1.0,
          duration: 100,
        },
        // move thumb in
        {
          translateX: 0,
          translateY: 0,
          scale: 1,
          duration: 1000,
          delay: 200,
        },
      ],
      complete: function () {
        anime({
          targets: ["#thumbTouch"],
          opacity: 1,
          duration: 100,
        });
        anime({
          targets: ["#holdHere"],
          opacity: 0,
          duration: 100,
        });
      },
    });

    demoAnimation.finished.then(this.demoSwipe);
  };

  demoSwipe = () => {
    var swipeAnimation = anime.timeline({
      easing: "linear",
    });
    // hand
    swipeAnimation.add(
      {
        targets: ["#hand"],
        keyframes: [
          // prepare
          {
            translateX: 3 * getWidth()! * this.state.scaleFactor,
            translateY: (getHeight()! / 2) * this.state.scaleFactor,
            scale: 1.3,
            duration: 100,
          },
          {
            opacity: 1.0,
            duration: 100,
          },
          // move hand in
          {
            translateX: 0,
            translateY: 0,
            scale: 1,
            duration: 1500,
          },
        ],
      },
      "+=200"
    );

    swipeAnimation.add({
      targets: ["#handTouch"],
      opacity: 1,
      duration: 100,
    });

    swipeAnimation.add(
      {
        targets: ["#hand", "#handTouch"],
        translateX: 400 * this.state.scaleFactor,
        duration: 1500,
      },
      "+=500"
    );

    swipeAnimation.add(
      {
        targets: ["#hand", "#handTouch"],
        translateX: 10 * this.state.scaleFactor,
        translateY: 450 * this.state.scaleFactor,
        duration: 1500,
      },
      "+=500"
    );

    swipeAnimation.add(
      {
        targets: ["#hand", "#handTouch"],
        translateX: 400 * this.state.scaleFactor,
        translateY: 450 * this.state.scaleFactor,
        duration: 1500,
      },
      "+=500"
    );

    swipeAnimation.add(
      {
        targets: ["#handTouch"],
        keyframes: [
          {
            opacity: 0,
            duration: 200,
          },
          {
            translateX: 0,
            translateY: 0,
            duration: 100,
          },
        ],
      },
      "+=500"
    );

    swipeAnimation.add(
      {
        targets: ["#hand"],
        keyframes: [
          {
            translateX: 3 * getWidth()! * this.state.scaleFactor,
            translateY: (getHeight()! / 2) * this.state.scaleFactor,
            scale: 1.3,
            duration: 1500,
          },
          {
            opacity: 0.0,
            duration: 100,
          },
        ],
      },
      "-=100"
    );

    swipeAnimation.finished.then(this.demoSwipe);
  };

  getScaled = (x: number): number => {
    return x * this.state.scaleFactor;
  };

  z1Detected = () => {
    console.log("zP1 detected");

    anime({
      targets: "#pulse1",
      scale: 1.0,
      opacity: 1.0,
      duration: 500,
      easing: "linear",
      complete: () => {
        anime({
          targets: "#pulse1",
          scale: 0.95,
          duration: 1000,
          loop: true,
          direction: "alternate",
          easing: "linear",
        });
      },
    });
  };

  z2Detected = () => {
    console.log("zP2 detected");

    anime({
      targets: ["#pulse1", "#pulse2"],
      scale: 1.0,
      opacity: 1.0,
      duration: 500,
      easing: "linear",
      complete: () => {
        anime({
          targets: ["#pulse1", "#pulse2"],
          scale: 0.95,
          duration: 700,
          loop: true,
          direction: "alternate",
          easing: "linear",
        });
      },
    });
  };

  z3Detected = () => {
    console.log("zP3 detected");

    anime({
      targets: ["#pulse1", "#pulse2", "#pulse3"],
      scale: 1.0,
      opacity: 1.0,
      duration: 500,
      easing: "linear",
      complete: () => {
        anime({
          targets: ["#pulse1", "#pulse2", "#pulse3"],
          scale: 0.95,
          duration: 400,
          loop: true,
          direction: "alternate",
          easing: "linear",
        });
      },
    });
  };

  z4Detected = () => {
    console.log("zP4 detected");

    anime({
      targets: ["#pulse1", "#pulse2", "#pulse3"],
      scale: 1.0,
      opacity: 1.0,
      duration: 500,
      easing: "linear",
      complete: () => {
        anime({
          targets: ["#pulse1", "#pulse2", "#pulse3"],
          scale: 0.95,
          duration: 250,
          loop: true,
          direction: "alternate",
          easing: "linear",
        });
      },
    });
  };

  zSuccess = () => {
    console.log("Z correct");

    this.playSound();
    var circle = document.getElementById("correctCircle")!;
    circle.style.transformOrigin = "center";
    circle.style.opacity = "1.0";
    circle.style.zIndex = "100";

    // scale red circle to full screen
    // TODO fix scale?
    anime({
      targets: "#correctCircle",
      scale: 5,
      duration: 500,
      easing: "easeInQuad",
      complete: () => {
        this.setState({ redirect: "/verify" });
      },
    });
  };

  zNoSuccess = () => {
    console.log("Z incorrect");

    anime.remove(["#pulse1", "#pulse2", "#pulse3"]);

    anime({
      targets: "#pulse1",
      scale: 0.8,
      opacity: 0.0,
      duration: 500,
    });
    anime({
      targets: "#pulse2",
      scale: 0.7,
      opacity: 0.0,
      duration: 500,
    });
    anime({
      targets: "#pulse3",
      scale: 0.6,
      opacity: 0.0,
      duration: 500,
    });
  };

  playSound = () => {
    let audio = new Audio(sound);
    audio.play();
  };

  render() {
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }

    return (
      <div
        id="swipeScreen"
        className="absolute top-0 left-0 w-screen h-screen overflow-hidden"
      >
        <Helmet>
          <meta
            name="viewport"
            content="viewport-fit=cover, width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
          />

          <meta name="format-detection" content="telephone=no" />
          <meta name="msapplication-tap-highlight" content="no" />

          <meta name="apple-mobile-web-app-capable" content="yes" />
          <meta name="apple-mobile-web-app-title" content="Prisma Key" />
          <meta name="apple-mobile-web-app-status-bar-style" content="black" />
        </Helmet>
        <Circle id="pulse1" color="#ED1C2933" radius={this.getScaled(440)} />
        <Circle id="pulse2" color="#ED1C2933" radius={this.getScaled(520)} />
        <Circle id="pulse3" color="#ED1C2933" radius={this.getScaled(600)} />

        <Circle
          id="correctCircle"
          color="#ED1C29"
          radius={this.getScaled(360)}
        />
        <ScaledImage
          src={z_lines}
          id="z_lines"
          alt="z_lines"
          scaleFactor={this.state.scaleFactor}
        />

        <ScaledImage
          src={placement}
          id="placement"
          alt="placement"
          scaleFactor={this.state.scaleFactor}
        />
        <ScaledImage
          src={card}
          id="card"
          alt="card"
          scaleFactor={this.state.scaleFactor}
        />
        <ScaledImage
          src={holdHere}
          id="holdHere"
          alt="holdHere"
          scaleFactor={this.state.scaleFactor}
        />
        <ScaledImage
          src={thumbTouch}
          id="thumbTouch"
          alt="thumbTouch"
          scaleFactor={this.state.scaleFactor}
        />
        <ScaledImage
          src={thumb}
          id="thumb"
          alt="thumb"
          scaleFactor={this.state.scaleFactor}
        />
        <ScaledImage
          src={handTouch}
          id="handTouch"
          alt="handTouch"
          scaleFactor={this.state.scaleFactor}
        />
        <ScaledImage
          src={hand}
          id="hand"
          alt="hand"
          scaleFactor={this.state.scaleFactor}
        />
      </div>
    );
  }
}

export default ZScreen;
